<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>隔离点工作人员</span>
      </div>
      <el-form class="right" label-width="80px">
        <el-form-item label="隔离点：">
          <el-select v-model="status" filterable placeholder="请选择隔离点">
            <el-option
              v-for="item in governmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字：">
          <el-input v-model="keywords" placeholder="请输入关键字..."></el-input>
        </el-form-item>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button @click="cancelSearch">清除搜索</el-button>
        <el-button @click="add" v-if="userInfo.role_id == 1">添加</el-button>
        <el-button @click="output">导出</el-button>
        <el-button @click="showImport = !0">导入</el-button>
      </el-form>
    </div>
    <el-table :data="list" row-key="id"
      ><el-table-column prop="id" label="编号" align="center"></el-table-column>
      <el-table-column
        prop="teacher"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="positioning"
        label="岗位"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="role_name"
        label="角色"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="电话"
        align="center"
      ></el-table-column>
      <el-table-column prop="goods_name" label="性别" align="center">
        <template v-slot="{ row }">
          {{ row.sex ? "女" : "男" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="current_temperature"
        label="当前体温"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="maximum_temperature"
        label="最高体温"
        align="center"
      ></el-table-column>
      <el-table-column prop="age" label="年龄" align="center"></el-table-column>
      <el-table-column
        prop="address"
        label="现住址"
        align="center"
      ></el-table-column>
      <el-table-column prop="goods_name" label="上岗时间" align="center">
        <template v-slot="{ row }">
          <span v-if="row.teacher_income_time">{{
            getDateformat(row.teacher_income_time)
          }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="teacher_comefrom" label="出勤单位" align="center">
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center">
      </el-table-column>
      <el-table-column
        prop="goods_name"
        label="操作"
        align="center"
        width="160"
      >
        <template v-slot="{ row }">
          <el-button size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button v-if="userInfo.role_id == 1" size="small" type="danger" @click="handleDel(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
    <import-file
      v-if="showImport"
      :url="$api.teacher.importTeacher"
      fileUrl="teacher.xlsx"
      fileName="工作人员模板.xlsx"
      :showImport="showImport"
      @importSuccess="importSuccess"
    ></import-file>
  </el-main>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
import importFile from "@/components/importFile";
export default {
  components: {
    importFile,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      keywords: "",
      list: [],
      governmentList: [],
      showImport: !1,
      total_number: 0,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.getList();
    this.getGovernmentList();
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      if (this.keywords) data.keywords = this.keywords;
      this.$axios.post(this.$api.teacher.list, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    importSuccess() {
      this.showImport = !1;
      this.getList();
    },
    //导出数据
    output() {
      this.$axios.post(this.$api.teacher.output).then((res) => {
        if (res.code == 0) {
          console.log(res);
          let a = document.createElement("a");
          a.href = res.result.link;
          a.id = "download";
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById("download");
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //删除
    handleDel(row) {
      this.$confirm("确认删除此工作人员？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.post(this.$api.teacher.del, { id: row.id }).then((res) => {
          if (res.code == 0) {
            this.list = this.list.filter((item) => item.id != row.id);
            this.total_number--;
            this.$message.success("删除成功");
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //获取隔离点
    getGovernmentList() {
      this.$axios
        .post(this.$api.student.stateNum, { rows: 10000 })
        .then((res) => {
          if (res.code == 0) {
            this.governmentList = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    cancelSearch() {
      this.keywords = "";
      this.getList();
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    handleEdit(row) {
      this.$router.push({
        path: "/home/AddLsolationPointMedicalStaff",
        query: {
          id: row.id,
        },
      });
    },
    add() {
      this.$router.push("/home/AddLsolationPointMedicalStaff");
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      display: flex;
      .el-form-item {
        margin: 0;
      }
      .el-input {
        width: 270px;
        margin-right: 20px;
      }

      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409eff;
      }
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
}
</style>
